@import "./_variables";

h1 {
  font-size: 2.25em;
  font-weight: bold;
  color: $purple;
}

h2 {
  font-size: 1.75em;
  font-weight: bold;
}

h3 {
  font-size: 1.25em;
  font-weight: bold;
}

h4 {
  font-size: 1.1em;
  font-weight: bold;
}

.box {
  margin: 2em 4em;
}

.container-fluid {
  width: auto;
  padding-left: 0em;
  padding-right: 0em;
}

.main-body {
  min-height: calc(100vh - 15rem);
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.date {
  color: $gray;
  font-size: 0.8rem;
}
