@import "./_variables";

.newsfeed {
  height: 100%;
  background-color: #f6f6f6;
  padding: 1em 2em 2em 2em;

  .header {
    padding-bottom: 0.5em;
    border-bottom: $purple 0.3em solid;
  }

  .newsItem {
    padding: 2em 0;
    border-bottom: lightgray 0.01em solid;

    a {
      color: black;
      text-decoration: none;

      &:hover {
        .title {
          text-decoration: underline;
        }

        .content {
          text-decoration: none;
        }

        .meta {
          text-decoration: none;
        }
      }
    }

    .content {
      color: #636363;
    }

    .meta {
      margin-top: 0.5em;
      color: #636363;
    }
  }

  .viewMore {
    text-align: center;
    padding: 2em 0;

    a {
      color: $purple;
      text-decoration: none;

      &:hover {
        color: mix(white, $purple, 30%);
        text-decoration: underline;
      }
    }

    @media only screen and (max-width: 992px) {
      padding: 2em 0 0;
    }
  }
}
