@import "./_variables";

.navbar {
  .container-fluid {
    width: 100%;
  }
}

.logo {
  width: 5em;
}

#navbar {
  padding: 1em;

  a {
    padding-right: 2em;

    color: black;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}
