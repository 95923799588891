@import "./_variables";

.category-container {
  margin: 0 10%;

  h1 {
    text-align: center;
    margin: 0.5em 0 1em;
  }

  .date {
    margin: 0;
    font-size: 0.8rem;
  }
}
