.storycards.ui.cards {
  justify-content: center;
}

.storycards.ui.three.cards {
  margin: 1.25em;
}

.storycards.ui.three.cards > .card {
  width: calc(33.33333333% - 4.5em);
  margin: 1.8em;
}

.ui.card,
.ui.cards > .card,
.ui.cards a.card:hover,
.ui.link.card:hover,
.ui.link.cards .card:hover,
a.ui.card:hover {
  box-shadow: none;
}

.ui.card > .image,
.ui.cards > .card > .image {
  background: none;
}

.ui.card > .image img,
.ui.cards > .card > .image img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 0.28571429rem;
}

.content {
  color: black;
  margin-top: 0.6em;
  font-size: 0.8rem;
}

.storycards {
  a {
    color: black;

    .title {
      margin-top: 0.6em;
    }

    &:hover {
      text-decoration: none;

      .title {
        text-decoration: underline;
      }

      .content {
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .ui.stackable.cards > .card {
    margin: 3em 1em !important;
  }
}
